import { Img } from 'components';
import React, { useEffect, useRef, useState } from 'react';
import { panaromaScenes } from 'constants/panaroma';
import { hidePopup, hideSafariTabsSafeArea } from 'helpers';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
// import { useDebounce } from 'Hook/useDebounce';
import { stateOffImg, stateOnImg } from 'assets/images';
import { showSimpleMessage } from 'utils/notify';
import useDeviceOrientation from 'Hook/useIsDeviceOrientationOn';
import {
  tour_desktop_cross,
  tour_large_cross,
  tour_mobile_cross,
  tour_mobile_cross_simple,
  tour_tab_cross,
} from 'assets';
var viewer = null;

const indMap = {
  0: 'lavatory',
  1: 'front',
  2: 'middle',
  3: 'middle-rear',
  4: 'rear',
};
// let oldPitch = null;
// let oldYaw = null;

/**
 * Virtualview component
 * @param {function} handleButtonClick button click handler
 * @param {boolean} visible whether the component is visible or not
 * @param {function} setVisible setter for visible
 * @param {boolean} bookingVisible whether the booking page is visible or not
 * @returns {React.ReactElement} Virtualview component
 */
const Virtualview = ({ handleButtonClick, visible, setVisible, bookingVisible = false }) => {
  // eslint-disable-next-line no-unused-vars
  const [renderCount, setRenderCount] = useState(0);
  const isDeviceOrientationEnabled = useDeviceOrientation();
  const [imageURLInd, setImageURLInd] = useState(2);
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:699px)');
  const isTab = useMediaQuery('(max-width:1049px)');
  const isPage = location?.pathname?.indexOf('virtual-view') > -1 ? true : false;
  const isBooking = location?.pathname?.indexOf('booking') > -1 ? true : false;
  const [gyroPermission, setGyroPermission] = useState(false);
  const msgRef = useRef(null);
  // console.log(isPage, isDeviceOrientationEnabled, 'this_isPage');

  // console.log('isDeviceOrientationEnabled', isDeviceOrientationEnabled);
  // const isActive = viewer?.isOrientationActive();
  // useMemo(() => {
  //   setGyroPermission(viewer?.isOrientationActive());
  // }, [isActive]);

  // const debouncedValue = useDebounce(renderCount, 1000);

  const ref = useRef(null);

  useEffect(() => {
    if (isPage) {
      hideSafariTabsSafeArea();
    }

    if (bookingVisible) {
      hidePopup();
    }
    if (window.pannellum && ref.current) {
      handleImageChange();
    }
    // Cleanup on unmount
    return () => {
      if (viewer) {
        viewer.destroy();
        viewer = null;
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, visible, bookingVisible]);

  /**
   * Handles the change of the image in the virtual view component.
   * It will destroy the current viewer and create a new one with the new image.
   * @function
   */
  const handleImageChange = () => {
    if (!ref.current || !window.pannellum) {
      console.error('Pannellum or ref is not available');
      return;
    }

    try {
      if (ref.current) {
        if (window.pannellum && viewer) {
          viewer.destroy();
        }
        viewer = window.pannellum.viewer(ref.current, {
          default: {
            firstScene: indMap[imageURLInd],
            autoRotate: 1,
            autoLoad: true,
            sceneFadeDuration: 2000,
            autoRotateInactivityDelay: 30000,
            showZoomCtrl: false,
            keyboardZoom: false,
            mouseZoom: false,
            disableKeyboardCtrl: true,
            showFullscreenCtrl: false,
            showControls: false,
            hfov: isMobile ? 50 : isTab ? 75 : 100,
            minHfov: isMobile ? 30 : isTab ? 55 : 80,
            maxHfov: isMobile ? 50 : isTab ? 75 : 100,

            // orientationOnByDefault: bookingVisible === 'Initial' ? gyroPermission : bookingVisible,
            // startOrientation: bookingVisible === 'Initial' ? gyroPermission : bookingVisible,
            // destroy: !bookingVisible,
            // "hotSpotDebug":true
          },
          scenes: panaromaScenes(setImageURLInd),
          // destroy: !bookingVisible ? gyroPermission : !bookingVisible,
        });

        // stopOrientation()
      }
    } catch (error) {
      console.error(error, 'handleImageChange');
    }
    window.viewer = viewer;
  };

  /**
   * Starts the orientation of the panorama viewer, if supported by the device.
   * The gyro permission is also set to true.
   */
  const _startOrientation = async () => {
    if (viewer && viewer?.isOrientationSupported()) {
      await viewer?.startOrientation();
      setGyroPermission(true);
    }
  };

  /**
   * Stops the orientation of the panorama viewer, if active.
   * Also sets the gyro permission to false.
   */
  const stopOrientation = async (e) => {
    await viewer?.stopOrientation();
    setGyroPermission(viewer?.isOrientationActive());
    // if (viewer && viewer?.isOrientationActive()) {
    // }
  };

  /**
   * Changes the scene of the panorama viewer to the scene specified by the current image index.
   * If the scene is front or rear, a deviation of 180 degrees is added to the yaw if the image index is 2 or 4.
   * If the scene is middle, a deviation of 180 degrees is added to the yaw if the image index is 1.
   * The loadScene method is called with the new scene and yaw values, and the new pitch value.
   */
  const changeScene = () => {
    if (viewer) {
      try {
        const newYaw = viewer.getYaw();
        const newPitch = viewer.getPitch();
        const scene = viewer.getScene();
        let deviation = 0;
        if (scene === 'front') {
          deviation = imageURLInd === 4 || imageURLInd === 2 ? 180 : 0;
        } else if (scene === 'rear') {
          deviation = imageURLInd === 1 ? 180 : 0;
        } else if (scene === 'middle') {
          deviation = imageURLInd === 1 ? 180 : 0;
        }
        viewer.loadScene(indMap[imageURLInd], newPitch, newYaw + deviation);
        // viewer.setNorthOffset(northOffset)
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleTouchStart = (event) => {
    // setGyroPermission(false);
    // setRenderCount((prev) => prev + 1);
    stopOrientation();
  };

  /**
   * Handles the click event on the panorama viewer.
   * If the device supports the Device Orientation API, it requests permission for the API.
   * If the permission is granted, it starts the orientation of the panorama viewer and sets the gyro permission to true.
   * If the permission is not granted, it does not start the orientation of the panorama viewer.
   * If the device does not support the Device Orientation API, it starts the orientation of the panorama viewer and sets the gyro permission to true.
   */
  const onClick = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // setGyroPermission(true);
    // feature detect
    // setGyroPermission(true);

    if (typeof DeviceOrientationEvent.requestPermission === 'function') {
      DeviceOrientationEvent.requestPermission()
        .then((permissionState) => {
          if (permissionState === 'granted') {
            _startOrientation();
            // setGyroPermission(true);
            handleShowMessage();
          }
        })
        .catch(console.error);
    } else {
      // other browsers
      _startOrientation();
      if (e) {
        handleShowMessage();
      }
    }
  };

  /**
   * Shows a message to the user, informing them to point their phone
   * towards the desired direction and tap the arrows to advance forward.
   * The message is displayed for 10 seconds.
   */
  const handleShowMessage = () => {
    showSimpleMessage(
      <>
        Point your phone towards the desired direction. <br /> Tap arrows to advance forward
      </>,
      '12px',
      7 * 1000, // 7 seconds
    );
  };

  useEffect(() => {
    changeScene();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageURLInd, isPage]);

  useEffect(() => {
    if (isDeviceOrientationEnabled) {
      _startOrientation();
      console.count('checking_function_call');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeviceOrientationEnabled]);

  useEffect(() => {
    if (viewer?.isLoaded()) {
      return;
    }
    let loopInterval = null;
    if (!viewer?.isLoaded()) {
      loopInterval = setInterval(() => {
        if (viewer?.isLoaded() && msgRef.current) {
          setRenderCount((pre) => {
            if (pre === 0 && isDeviceOrientationEnabled) {
              handleShowMessage();
            }
            return 1;
          });
          msgRef.current.style.animation = 'fadeOut 4.5s ease-in-out 0.5s 1 forwards';
          clearInterval(loopInterval);
        }
      }, 1000);
    }
    return () => clearInterval(loopInterval);
  }, [msgRef, isDeviceOrientationEnabled]);

  const srcSet = isMobile
    ? [
        {
          media: '(max-width: 699px)',
          srcSet: `${tour_mobile_cross_simple} 699w`,
          sizes: '699px',
        },
      ]
    : [
        {
          media: '(max-width: 699px)',
          srcSet: `${tour_mobile_cross} 699w`,
          sizes: '699px',
        },
        {
          media: '(max-width: 1049px)',
          srcSet: `${tour_tab_cross} 1049w`,
          sizes: '1049px',
        },
        {
          media: '(max-width: 1799px)',
          srcSet: `${tour_desktop_cross} 1799w`,
          sizes: '1799px',
        },
        {
          srcSet: `${tour_large_cross} 2560w`,
          sizes: '2560px',
        },
      ];

  return (
    <div
      className={`relative ${!isPage && !isBooking ? 'mt-[70px]' : ''} h-screen airplane-panorama`}
    >
      {!isPage && (
        <Img
          src={'https://assets.blackjet.au/images/Close.svg'}
          alt='Close'
          onClick={handleButtonClick}
          className={`close-img-icon absolute rounded-full top-14 cursor-pointer left-8 z-[100] ${
            isMobile ? 'close-img-icon-mobile' : ''
          }`}
          srcSet={srcSet}
        />
      )}
      {/* <iframe
        ref={ref}
        sandbox="allow-scripts allow-same-origin"
        loading="lazy"
        src="https://raz.ro/temp/v5/" // Replace with the correct path to your index.html file
        title="VirtualRoute"
        style={iframeStyle}

      /> */}
      <ul className='options'>
        <li onClick={() => setImageURLInd(0)}>Lavatory</li>
        <li onClick={() => setImageURLInd(1)}>Front</li>
        <li onClick={() => setImageURLInd(2)}>Middle</li>
        {/* <li onClick={() => setImageURLInd(3)}>Mid-Rear</li> */}
        <li onClick={() => setImageURLInd(4)}>Rear</li>
      </ul>
      {(visible || isPage) && (
        <div>
          {isMobile && (
            <div role='button'>
              {gyroPermission ? (
                <img
                  onClick={stopOrientation}
                  className={`virtual-viw-orientation-icon ${!isPage && 'virtual-view-not-page'}`}
                  src={stateOnImg}
                  alt='on'
                />
              ) : (
                <img
                  className={`virtual-viw-orientation-icon ${!isPage && 'virtual-view-not-page'}`}
                  onClick={onClick}
                  src={stateOffImg}
                  alt='off'
                />
              )}
              <div
                className={`virtual-viw-orientation-icon toggle-orientation-msg ${
                  !isPage ? 'virtual-view-not-page-msg' : ''
                } `}
                ref={msgRef}
              >
                Tap to toggle device orientation
              </div>
            </div>
          )}
          <div onTouchEndCapture={handleTouchStart} id='panorama' ref={ref}></div>
        </div>
      )}
    </div>
  );
};

export default Virtualview;
