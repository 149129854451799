import { useState, useEffect } from 'react';

const useDeviceOrientation = () => {
  const [isDeviceOrientationEnabled, setIsDeviceOrientationEnabled] = useState(false);

  useEffect(() => {
    const checkDeviceOrientation = () => {
      if ('DeviceOrientationEvent' in window) {
        // Handle iOS 13+ permission request
        if (typeof DeviceOrientationEvent.requestPermission === 'function') {
          DeviceOrientationEvent.requestPermission()
            .then((response) => {
              if (response === 'granted') {
                setIsDeviceOrientationEnabled(true);
                window.addEventListener('deviceorientation', handleDeviceOrientation);
              } else {
                setIsDeviceOrientationEnabled(false);
              }
            })
            .catch((error) => {
              console.error('DeviceOrientation permission error:', error);
              setIsDeviceOrientationEnabled(false);
            });
        } else {
          // Supported without permission
          setIsDeviceOrientationEnabled(true);
          window.addEventListener('deviceorientation', handleDeviceOrientation);
        }
      } else {
        // DeviceOrientation not supported
        setIsDeviceOrientationEnabled(false);
      }
    };

    const handleDeviceOrientation = (event) => {
      if (event.alpha !== null || event.beta !== null || event.gamma !== null) {
        setIsDeviceOrientationEnabled(true);
      } else {
        setIsDeviceOrientationEnabled(false);
      }
    };

    checkDeviceOrientation();

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('deviceorientation', handleDeviceOrientation);
    };
  }, []);

  return isDeviceOrientationEnabled;
};

export default useDeviceOrientation;
