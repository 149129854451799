import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import './styles/index.css';
import './styles/style.scss';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find the root element');
}

const helmetContext = {};

const app = (
  <HelmetProvider context={helmetContext}>
    <App />
  </HelmetProvider>
);

hydrateRoot(rootElement, app);
// ReactDOMServer.renderToStaticMarkup(<div>Hello </div>);

// console.log(renderToString(app), 'app_content');

// const root = ReactDOM.createRoot(rootElement);
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
// );
