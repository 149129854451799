import React, { useEffect, useState } from 'react';
import { getCategoryList, getFaqQuestions } from 'services/api';

import { useMediaQuery } from '@mui/material';
import { handleFaqSlide, isFaqOpened } from 'helpers';
import { Entermobilegetapp } from 'components/Popup';
import { useNavigate, useLocation } from 'react-router-dom';
import { isEmpty } from 'helpers/common';
import FramerMotion from 'components/animations/FramerMotion';
import { ROUTE_LIST } from 'routes/routeList';
// import { Helmet } from 'react-helmet-async';
import { FaqsMeta } from 'components/meta/BlackjetMeta';
import { FaqStructureData } from 'components/meta/HomeStructureData';

/**
 * The Faq component renders a list of FAQs from the API and allows the user to select a category.
 * It also renders a form to submit an enquiry.
 *
 * @return {ReactElement} The Faq component.
 */
const Faq = () => {
  const [questions, setQuestions] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [limit] = useState(100);
  const [structuredData, setStructuredData] = useState([]);
  console.log(structuredData, 'structuredData_Faq');
  const isTabMobile = useMediaQuery('(max-width : 530px)');
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await getCategoryList();
        setCategoryList(res?.data?.data);
        setSelectedCategory(res?.data?.data[0] || {});
        getFaqQuestionsInfo(res.data.data[0]?._id);
      } catch (error) {
        console.log(error);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFaqQuestionsInfo = async (id) => {
    try {
      const res = await getFaqQuestions(id, limit);
      // console.log(res.data);
      const data = res?.data?.data;
      console.log('data1212', data);
      setQuestions(data);
      setSelectedCategory((prev) => {
        setStructuredData({
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          categoryName: prev.name,
          faq: data.map((item) => {
            return {
              '@type': 'Question',
              name: item.question,
              acceptedAnswer: {
                '@type': 'Answer',
                text: item.answer.replace(/<\/?[^>]+(>|$)/g, ''), // Strip HTML tags for plain text
              },
            };
          }),
        });
        return prev;
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isTabMobile && location.pathname === ROUTE_LIST.FAQ) {
      if (!isFaqOpened()) {
        handleFaqSlide(true);
      }
    }
  }, [isTabMobile, location.pathname]);

  const handleFaqClicked = (category) => {
    setSelectedCategory(category);
    getFaqQuestionsInfo(category?._id);
  };

  /**
   * Handles the closing of the FAQ page, navigating either to the previous
   * page in the history stack (if available) or to the landing page.
   */
  const handleCloseFaq = () => {
    if (window.history.length > 2 && !isEmpty(window.history.state)) {
      // Check for at least two entries (SPA + initial visit)
      navigate(-1);
    } else {
      navigate('/'); // Redirect to landing page if no previous history
    }

    // handleFaqSlide(false);
  };


  return (
    <>
      <FaqStructureData selectedTab={selectedCategory} selectedTabData={questions} />

      <FramerMotion>
        <FaqsMeta />

        <Entermobilegetapp />
        <div id='mobile-faq' className='faq-main-wrapper' onWheel={(e) => e.stopPropagation()}>
          <div className='faq-heading mobile-hide'>Frequently Asked Questions</div>
          <div className='faq-heading faqm fixed-header-pages sticky-tab'>
            <img
              className='close-btn'
              src='https://assets.blackjet.au/images/close-icon-white.svg'
              alt='close-icon-white'
              onClick={handleCloseFaq}
            />
            <span className='mob-faq-heading'>FAQs</span>
            <div id='FAQCategoriesRoot' className='faq-tabs-wrap'>
              {categoryList?.map((category) => (
                <div
                  key={category?._id}
                  id='FAQSections'
                  className={` ${
                    category._id === selectedCategory._id ? ' !bg-[#616161] ' : ' bg-[black] '
                  } faq-card`}
                  onClick={() => handleFaqClicked(category)}
                >
                  <div className='faq-img'>
                    {' '}
                    <img src={category?.image} alt='FAQIcons' id='FAQIcons' />
                  </div>
                  <div className='faq-card-header'>{category?.name}</div>
                </div>
              ))}
            </div>
          </div>

          <div className='card-grid-section faq-card-wapper'>
            {questions?.map((data, index) => {
              return (
                <div
                  className={`faq-card-it ${isTabMobile ? 'mobile-faq-container' : ''}`}
                  key={index}
                >
                  <div
                    id={`QRoot-${index}`}
                    className={`accordion-item ${
                      !isTabMobile || activeIndex === index ? 'active' : ''
                    }`}
                    onClick={() => toggleAccordion(index)}
                  >
                    <div className='ques-f'>
                      <div className='ques-txt'>{data?.question}</div>
                      <img
                        src='https://assets.blackjet.au/images/down-arrow.svg'
                        alt='Vector'
                        className={`arrow-icon ${activeIndex === index ? 'expanded' : ''}`}
                      />
                      <img
                        src='https://assets.blackjet.au/images/chevron-up.svg'
                        alt='Down Arrow'
                        className={`down-arrow-icon ${activeIndex === index ? 'collapsed' : ''}`}
                      />
                    </div>

                    {(!isTabMobile || activeIndex === index) && <hr className='divider' />}

                    <article>
                      <div
                        className={`ans-txt ${!isTabMobile || activeIndex === index ? 'show' : ''}`}
                        dangerouslySetInnerHTML={{ __html: data?.answer }}
                      ></div>
                    </article>
                  </div>
                </div>
              );
            })}
          </div>
          <span className='bottom-trademark'>©2023 Black Jet Mobility Pty Ltd</span>
        </div>
      </FramerMotion>
    </>
  );
};

export default Faq;
