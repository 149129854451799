import { useEffect, useRef } from 'react';

/**
 * Custom hook to trigger a callback after 5 minutes of inactivity
 * (no mouse or keyboard events detected).
 *
 * @param {Function} onInactivity - The callback to trigger after inactivity.
 */
export function useInactivityTrigger(onInactivity) {
  const timeoutRef = useRef(null);

  // Reset the inactivity timer
  const resetInactivityTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    // Start a new 5-minute timer
    timeoutRef.current = setTimeout(() => {
      onInactivity();
    },  20 * 1000); // 5 minutes
  };

  useEffect(() => {
    if (typeof onInactivity !== 'function') {
      console.warn('onInactivity must be a function.');
      return;
    }

    // Event listener for user activity
    const handleActivity = () => {
      resetInactivityTimer();
    };

    // Attach event listeners
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    // Initialize the inactivity timer
    resetInactivityTimer();

    // Cleanup on unmount
    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onInactivity]);

  return null;
}
